"use client";
import { ExclamationMark } from "@phosphor-icons/react";
import type { Metadata } from "next";
import { useEffect, useState } from "react";

export const metadata: Metadata = { title: "Not Found" };

export default function NotFound() {
	const [eventId, setEventId] = useState("");
	useEffect(() => setEventId(crypto.randomUUID()), []);

	return (
		<main className="flex h-[calc(100vh_-_56px)] flex-col items-center justify-center space-y-8 p-6">
			<section className="text-center">
				<ExclamationMark className="mx-auto text-4xl text-primary-100" weight="fill" />
				<h1 className="text-3xl text-primary-400">[404] Page Not Found</h1>
				<p>The page you're looking for is in another castle.</p>
			</section>

			<div className="flex space-x-4">
				<button
					className="rounded-lg bg-gray-600 px-4 py-2 transition-all duration-300 hover:bg-gray-500"
					// onClick={history.back}
					type="button"
				>
					Go Back
				</button>

				<button
					className="rounded-lg bg-gray-600 px-4 py-2 transition-all duration-300 hover:bg-gray-500"
					// onClick={window.location.reload}
					type="button"
				>
					Reload
				</button>
			</div>

			<div className="text-gray-600 text-sm">Event ID: {eventId}</div>
		</main>
	);
}
